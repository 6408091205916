<template>
  <div>
    <v-sheet
      v-if="loading"
      class="d-flex align-center justify-center"
      style="height: 50vh"
    >
      <v-progress-circular
        indeterminate
        size="50"
        color="primary"
      ></v-progress-circular>
    </v-sheet>
    <div v-else>
      <v-sheet class="pa-5" elevation="1">
        <div
          class="header d-flex justify-space-between align-center mx-10 mt-10"
        >
          <h2>Order ID: {{ order.customId }}</h2>
          <h2>
            Status: <span class="mr-3" :class="[isPending(order.orderStatus) ? 'success--text' : 'error--text']">{{ order.orderStatus }}</span
            ><v-icon large v-if="order.orderStatus == 'Pending'">mdi-timer-sand</v-icon>
            <v-icon large v-else-if="order.orderStatus == 'Confirmed'" color="success">mdi-check-all</v-icon>
          </h2>
          <div class="footer">
            <v-btn color="success" class="mr-5" @click="confirmOrder">Confirm Trip</v-btn>

            <v-btn color="primary" class="mr-5">Modify and Confirm Trip</v-btn>

            <v-btn color="error" @click="declineTrip">Decline Trip</v-btn>
          </div>
        </div>
        <div class="content mt-10 mx-10">
          <v-row no-gutters>
            <!-- Order Info -->
            <v-col cols="4">
              <v-row no-gutters v-for="item in orderData" :key="item.title">
                <v-col cols="6">
                  <v-subheader class="secondary--text font-weight-bold">
                    {{ item.title }}
                  </v-subheader>
                </v-col>
                <v-col cols="6">
                  <v-subheader>
                  {{ item.value }}
                  </v-subheader>
                </v-col>
              </v-row>
            </v-col>
            <v-spacer></v-spacer>
            <!-- MAP -->
            <v-col cols="6" align-self="center">
              <div id="map" class="mt-5" style="height: 30em;"></div>
            </v-col>
          </v-row>
        </div>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import loading from '../../mapsLoader'

export default {
  data: () => ({
    config: {
        center: { lat: 14.7251902, lng: -17.4596118 },
        zoom: 13,
        disableDefaultUI: true,
      },
    loading: true,
    order: {},
    orderData: [],
    orders: [
      {
        id: 1,
        orderId: "SN-CMPR-0001",
        vanId: "SN-VC-112",
        customerId: "SN-CP-01",
        customerName: "Papa Loum",
        // babySeat: "N/A",
        payment: {
          amount: 5000,
          status: "OK",
        },
        createdAt: new Date("2021-08-01").toISOString(),
        trip: {
          date: "2021-08-15",
          time: "16:15-20:00",
          address: "Aéroport DSS-Kaolack",
        },
        tripInfo: {
          customerName: "Papa Loum",
          passengers: 6,
          luggages: 7,
        },
        driver: {
          id: "SN-CH-01",
          name: "Mamadou Diouf",
        },
        status: "Pending",
      },
    ],
  }),

  created() {
    setTimeout(async () => {
      this.order = await this.$store.dispatch('order/getOrderById', { id: this.$route.params.id })
      this.loading = false

      console.log(this.order)

      this.orderData = [
        {
          title: "Van",
          value: this.order.vanCustomId,
        },
        {
          title: "Driver",
          value: this.order.driverFullName,
        },
        {
          title: "Trip",
          value: this.order.dropOffLocation.name,
        },
        {
          title: "Order hour",
          value: `${moment.unix(this.order.orderDateTime).format("L H:mm")}`,
        },
        {
          title: "Order Date",
          value: this.order.trip.date,
        },
        {
          title: "Trip Hour",
          value: this.order.trip.time,
        },
        {
          title: "Client ID",
          value: 'SN-CP-01',
        },
        {
          title: "Client Name",
          value: this.order.customerFullName,
        },
        {
          title: "Passenger(s)",
          value: this.order.passengers,
        },
        {
          title: "Luggage(s)",
          value: this.order.luggages,
        },
        {
          title: "Baby Seat",
          value: this.order.babySeats,
        },
        {
          title: "Payment Amount",
          value: this.order.estimatedFare,
        },
        {
          title: "Distance",
          value: this.order.estimatedDistance,
        },
        {
          title: "Payment Status",
          value: this.order.payment.status,
        },
      ];

      loading.then(res => {
      if(res) {
        this.mapLoading = false
        setTimeout(() => {
          this.initMap()
        }, 1000)
      }
    })

      // this.initMap();
      // this.calculateFare()
    }, 2000);
  },

  methods: {

    isPending(status) {
      if(status == 'Pending') return true
      return false 
    },

    async confirmOrder() {
      await this.$store.dispatch('order/changeOrderStatus', { id: this.$route.params.id, status: 'Confirmed' })
      this.order.orderStatus = 'Confirmed'
    },

    async declineTrip() {
      await this.$store.dispatch('order/changeOrderStatus', { id: this.$route.params.id, status: 'Cancelled' })
      this.order.orderStatus = 'Cancelled'
    },

    async calculateFare() {
      const parameters = await this.$store.dispatch('getParameters')
      let seat = `0.${parameters.seatPercent}`
      let luggage = `0.${parameters.luggagePercent}`
      if(Number(this.order.passengers) < 5) {
        seat = '0'
      }

      if(Number(this.order.luggages) < 7) {
        luggage = '0'
      }

      let calculateDistance = Number(this.order.estimatedDistance.split(' ')[0]) * Number(parameters.pricePerKm)
      let travelPrice = Number(parameters.basicFlatRate) + calculateDistance
      let totalPrice = travelPrice * (1 + Number(seat) + Number(luggage))

      this.orderData[11].value = Math.round((totalPrice + Number.EPSILON) * 100) / 100

      // this.orderData[11].value = this.order.totalFare
    },


    initMap() {
      if(google && google.maps){
      const mapContainer = document.getElementById("map")
      this.map = new google.maps.Map(mapContainer, this.config)

      const directionsService = new google.maps.DirectionsService();
      const directionsRenderer = new google.maps.DirectionsRenderer();

      directionsService
          .route({
            origin: new google.maps.LatLng(`${this.order.pickUpLocation.lat}`, `${this.order.pickUpLocation.lng}`),
            destination: new google.maps.LatLng(`${this.order.dropOffLocation.lat}`, `${this.order.dropOffLocation.lng}`),
            travelMode: google.maps.TravelMode.DRIVING,
          })
          .then((response) => {
            directionsRenderer.setDirections(response);
          });

        directionsRenderer.setMap(this.map);
      }
    },
  },
};
</script>

<style></style>
